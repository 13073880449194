/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { InviteUserRequestDto, JwtTokenDetails } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AzureAuthController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags azure-auth-controller
   * @name ApiV1Oauth2MicrosoftInviteUsingPost
   * @summary microsoftInvite
   * @request POST:/api/v1/oauth2/microsoft/invite
   * @secure
   */
  apiV1Oauth2MicrosoftInviteUsingPost = (request: InviteUserRequestDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/api/v1/oauth2/microsoft/invite`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags azure-auth-controller
   * @name ApiV1Oauth2MicrosoftSigninUsingPost
   * @summary microsoftAuthentication
   * @request POST:/api/v1/oauth2/microsoft/signin
   * @secure
   */
  apiV1Oauth2MicrosoftSigninUsingPost = (query: { code: string }, params: RequestParams = {}) =>
    this.request<JwtTokenDetails, void>({
      path: `/api/v1/oauth2/microsoft/signin`,
      method: "POST",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
