import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { getCurrentUser } from '../../services/UserService';
import { AuthTokenReducer, CurrentUserReducer } from '../../models/Redux';
import { removeFromLocalStorage } from '../../services/config/LocalStorage';
import { Token } from '../../models/enums/Token';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOADING = 'SET_LOADING';
export const ERROR_FETCHING = 'ERROR_FETCHING';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const LOG_OUT = 'LOG_OUT';

export const thunkGetCurrentUser = (
    callback?: () => void,
): ThunkAction<void, CurrentUserReducer, unknown, Action<string>> => dispatch => {
    dispatch({ type: SET_LOADING, payload: true });
    getCurrentUser()
        .then(payload => {
            dispatch({
                type: SET_CURRENT_USER,
                payload,
            });
            callback?.();
        })
        .catch(() => dispatch({ type: ERROR_FETCHING }));
};

export const thunkLogInUserTest = (): ThunkAction<void, AuthTokenReducer, unknown, Action<string>> => (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
    dispatch({
        type: SET_AUTHENTICATED,
    });
};

export const thunkLogoutUser = (): ThunkAction<
    void,
    CurrentUserReducer,
    unknown,
    Action<string>
> => dispatch => {
    dispatch({ type: LOG_OUT });
    removeFromLocalStorage(Token.TOKEN);
    removeFromLocalStorage(Token.REFRESH_TOKEN);
    window.location.replace(window.location.origin);
};
