import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Box, CircularProgress } from '@material-ui/core';
import { z } from 'zod';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import {
    ProjectGroupDto,
    ProjectDetailsDto,
    ProjectUpdateRequestDto,
    ContactDto,
} from '../../api/data-contracts';
import { queryKeys } from '../../react-query/constants';
import { getProjectById, updateProject } from '../../services/ProjectService';
import BackButton from '../../components/BackButton';
import ProjectTitle from '../ProjectDetailsPage/ProjectTitle';
import ProjectActions from '../ProjectDetailsPage/ProjectActions';
import ProjectFormTabs from './ProjectFormTabs';
import { SUBMITTING_DATETIME_FORMAT } from '../../config/constants';

const ProjectFormPage: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const formRef = useRef<HTMLFormElement>(null);

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [submitForm, setSubmitForm] = useState<boolean>(false);

    const { data: project, isLoading } = useQuery<ProjectDetailsDto>(
        [queryKeys.project, id],
        () => getProjectById(parseInt(id as string, 10)),
        {
            enabled: !!id,
        },
    );

    const FormSchema = z.object({
        projectName: z.string().trim().max(50, 'Maximum length is 50 characters'),
        projectDescription: z.string().trim().max(2000, 'Maximum length is 2000 characters'),
        projectDetailsLines: z
            .object({
                id: z.number(),
                valueId: z.number().optional(),
                name: z.string(),
                description: z.string().trim().max(100, 'Maximum length is 100 characters').nullable(),
            })
            .array(),
        projectDateLines: z
            .object({
                id: z.number(),
                valueId: z.number().optional(),
                name: z.string(),
                date: z.string().nullable(),
            })
            .array(),
        contactGroups: z
            .object({
                groupId: z.number(),
                topLevelGroup: z.string().trim(),
                role: z.string().trim(),
                contacts: z
                    .object({
                        contactId: z.number().nullable(),
                        companyId: z.number({ invalid_type_error: 'Required field' }),
                        contactType: z.string().optional(),
                        contactName: z
                            .string({ invalid_type_error: 'Required field' })
                            .trim()
                            .min(3, 'Minimum length is 3 characters')
                            .max(50, 'Maximum length is 50 characters')
                            .nullable(),
                        phoneNo: z.string().trim().max(255, 'Maximum length is 255 characters').nullable(),
                        email: z.string().trim().max(255, 'Maximum length is 255 characters').nullable(),
                    })
                    .refine(
                        ({ contactType, email, phoneNo }) => {
                            return contactType !== 'new'
                                ? true
                                : email?.trim()?.length || phoneNo?.trim()?.length;
                        },
                        {
                            path: ['email'],
                            message: 'Email OR Phone number required',
                        },
                    )
                    .refine(
                        ({ contactName, contactType }) => {
                            return !(contactType === 'new' && !contactName?.length);
                        },
                        {
                            path: ['contactName'],
                            message: 'Required field',
                        },
                    )
                    .array()
                    .refine(contacts => {
                        if (!contacts.length) {
                            enqueueSnackbar('Please enter at least one contact', { variant: 'error' });
                            return false;
                        }

                        return true;
                    }),
            })
            .array()
            .optional(),
        projectPictures: z
            .object({
                url: z.string(),
                id: z.number(),
                externalId: z.string().nullable(),
                mediaType: z.string().nullable(),
                size: z.number().nullable(),
            })
            .array()
            .refine(projectPictures => {
                if (projectPictures.length > 5) {
                    enqueueSnackbar('Maximum number of pictures is 5', { variant: 'error' });
                    return false;
                }

                return true;
            }),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const transformContactGroups = (groups: Record<string, Record<string, ProjectGroupDto>> | undefined) => {
        if (!groups) return [];

        const contacts: {
            groupId?: number | undefined;
            topLevelGroup: string;
            role: string;
            contacts?: ContactDto[] | undefined;
        }[] = [];
        Object.keys(groups).forEach(key => {
            if (key) {
                Object.keys(groups[key]).forEach(key2 => {
                    if (key2) {
                        contacts.push({ ...groups[key][key2], topLevelGroup: key, role: key2 });
                    }
                });
            }
        });

        return contacts;
    };

    const projectInfoToFormValues = useMemo(() => {
        return {
            projectName: project?.projectName || '',
            projectDescription: project?.projectDescription || '',
            projectDetailsLines:
                project?.projectDetailsLines?.map(line => ({
                    id: line?.id ? parseInt(line.id, 10) : undefined,
                    valueId: line?.valueId ? parseInt(line.valueId, 10) : undefined,
                    name: line.name,
                    description: line.description,
                })) || [],
            projectDateLines:
                project?.projectDateLines?.map(line => ({
                    id: line?.id ? parseInt(line.id, 10) : undefined,
                    valueId: line?.valueId ? parseInt(line.valueId, 10) : undefined,
                    name: line.name,
                    date: line.date,
                })) || [],
            contactGroups: transformContactGroups(project?.contactGroups),
            projectPictures: project?.projectPictures || [],
        };
    }, [project]);

    const methods = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: projectInfoToFormValues,
    });

    useEffect(() => {
        methods.reset(projectInfoToFormValues);
    }, [methods, project, projectInfoToFormValues]);

    const projectMutation = useMutation(
        (request: ProjectUpdateRequestDto) => updateProject(parseInt(id as string, 10), request),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([queryKeys.project]);
                enqueueSnackbar('Project successfully updated', { variant: 'success' });
                history.goBack();
            },
        },
    );

    const onSubmit: SubmitHandler<FormSchemaType> = async (formValues: FormSchemaType) => {
        const requestObject: ProjectUpdateRequestDto = {
            // @ts-ignore
            projectPictures:
                formValues.projectPictures.map(it => {
                    return { id: it.id };
                }) || [],
            projectName: formValues.projectName,
            projectDescription: formValues.projectDescription,
            // @ts-ignore
            contactGroups: formValues.contactGroups,
            projectDetailsLines: formValues.projectDetailsLines.map(line => ({
                ...line,
                description: line?.description || undefined,
            })),
            projectDateLines:
                formValues.projectDateLines?.map(line => ({
                    id: line?.id,
                    valueId: line?.valueId,
                    name: line?.name,
                    date: line?.date ? dayjs(line.date).format(SUBMITTING_DATETIME_FORMAT) : undefined,
                })) || [],
        };
        projectMutation.mutate(requestObject);
    };

    useEffect(() => {
        if (submitForm) {
            setSubmitForm(false);
            formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    }, [submitForm]);

    return (
        <Box width="100%">
            <BackButton label="back to project" />
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" paddingY={2}>
                    <CircularProgress />
                </Box>
            ) : project ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    mt="40px"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <ProjectTitle
                            project={project}
                            editMode
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                        <ProjectActions
                            project={project}
                            editMode
                            setSubmitForm={setSubmitForm}
                            isLoading={projectMutation.isLoading}
                        />
                    </Box>

                    <FormProvider {...methods}>
                        <form
                            ref={formRef}
                            onSubmit={methods.handleSubmit(onSubmit)}
                            style={{ width: '100%' }}
                        >
                            <ProjectFormTabs selectedTab={selectedTab} />
                        </form>
                    </FormProvider>
                </Box>
            ) : null}
        </Box>
    );
};

export default ProjectFormPage;
