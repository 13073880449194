import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        fontSize: '2.5rem',
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'black',
        paddingBottom: '0.938rem',
    },
    windowsIcon: {
        width: '3.125rem',
        color: '#0078dd',
    },
    signInWithMicrosoftButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        padding: '1.563rem',
        cursor: 'pointer',
    },
    signInWithMicrosoftButtonText: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        color: '#263644',
        margin: '0px 1.875rem',
    },
    loginCard: {
        width: 'fit-content',
        minWidth: '27.625rem',
        minHeight: '18.063rem',
        padding: '1.875rem',
        borderRadius: 0,
        borderTopLeftRadius: '2.188rem',
        borderBottomRightRadius: '2.188rem',
    },
});
