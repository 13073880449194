/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    JwtTokenDetails,
    PageOfUserRowDto,
    UserAvatarRequestDto,
    UserEnabledChangeRequestDto,
    UserInfoDto,
    UserProfileRequestDto,
    UserSignupRequestDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class UserController<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersUsingGet
     * @summary retrieveAll
     * @request GET:/api/v1/users
     * @secure
     */
    apiV1UsersUsingGet = (
        query?: {
            disciplines?: number[];
            endDate?: string;
            offset?: number;
            paged?: boolean;
            pageNumber?: number;
            pageSize?: number;
            search?: string;
            'sort.sorted'?: boolean;
            'sort.unsorted'?: boolean;
            startDate?: string;
            unpaged?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<PageOfUserRowDto, void>({
            path: `/api/v1/users`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersUsingPost
     * @summary create
     * @request POST:/api/v1/users
     * @secure
     */
    apiV1UsersUsingPost = (payload: UserSignupRequestDto, params: RequestParams = {}) =>
        this.request<JwtTokenDetails, void>({
            path: `/api/v1/users`,
            method: 'POST',
            body: payload,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersMeUsingGet
     * @summary retrieveCurrentUser
     * @request GET:/api/v1/users/me
     * @secure
     */
    apiV1UsersMeUsingGet = (params: RequestParams = {}) =>
        this.request<UserInfoDto, void>({
            path: `/api/v1/users/me`,
            method: 'GET',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersMeUsingDelete
     * @summary delete
     * @request DELETE:/api/v1/users/me
     * @secure
     */
    apiV1UsersMeUsingDelete = (params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/users/me`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersIdAvatarUsingPut
     * @summary updateAvatar
     * @request PUT:/api/v1/users/{id}/avatar
     * @secure
     */
    apiV1UsersIdAvatarUsingPut = (id: number, request: UserAvatarRequestDto, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/users/${id}/avatar`,
            method: 'PUT',
            body: request,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersIdAvatarUsingDelete
     * @summary deleteAvatar
     * @request DELETE:/api/v1/users/{id}/avatar
     * @secure
     */
    apiV1UsersIdAvatarUsingDelete = (id: number, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/users/${id}/avatar`,
            method: 'DELETE',
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersIdEnabledUsingPut
     * @summary updateEnabled
     * @request PUT:/api/v1/users/{id}/enabled
     * @secure
     */
    apiV1UsersIdEnabledUsingPut = (
        id: number,
        payload: UserEnabledChangeRequestDto,
        params: RequestParams = {},
    ) =>
        this.request<void, void>({
            path: `/api/v1/users/${id}/enabled`,
            method: 'PUT',
            body: payload,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersIdProfileUsingGet
     * @summary retrieve
     * @request GET:/api/v1/users/{id}/profile
     * @secure
     */
    apiV1UsersIdProfileUsingGet = (
        id: number,
        query?: { endDate?: string; startDate?: string },
        params: RequestParams = {},
    ) =>
        this.request<UserInfoDto, void>({
            path: `/api/v1/users/${id}/profile`,
            method: 'GET',
            query: query,
            secure: true,
            ...params,
        });
    /**
     * No description
     *
     * @tags user-controller
     * @name ApiV1UsersIdProfileUsingPut
     * @summary updateUser
     * @request PUT:/api/v1/users/{id}/profile
     * @secure
     */
    apiV1UsersIdProfileUsingPut = (id: number, request: UserProfileRequestDto, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/api/v1/users/${id}/profile`,
            method: 'PUT',
            body: request,
            secure: true,
            type: ContentType.Json,
            ...params,
        });
}
