import dayjs from 'dayjs';
import { UserController } from '../api/UserController';
import {
    InviteUserRequestDto,
    PageOfUserRowDto,
    UserInfoDto,
    UserProfileRequestDto,
} from '../api/data-contracts';
import { UserPhotoController } from '../api/UserPhotoController';
import { BaseQuery } from '../components/CustomTable/CustomTable';
import { AzureAuthController } from '../api/AzureAuthController';

export type UsersQuery = {
    search?: string;
    disciplines?: number[];
    startDate?: string;
    endDate?: string;
    sorted?: boolean;
    unsorted?: boolean;
};

export const getUsers = async (query: UsersQuery & BaseQuery): Promise<PageOfUserRowDto> => {
    const userApi = new UserController();
    const { data } = await userApi.apiV1UsersUsingGet({
        ...query,
        startDate:
            query.startDate !== undefined
                ? dayjs(query.startDate).utc().format('YYYY-MM-DDT00:00:00')
                : undefined,
        endDate:
            query.endDate !== undefined
                ? dayjs(query.endDate).utc().format('YYYY-MM-DDT23:59:59')
                : undefined,
    });

    return data;
};

export const getUserById = async (id: number, startDate?: string, endDate?: string): Promise<UserInfoDto> => {
    const userApi = new UserController();

    const { data } = await userApi.apiV1UsersIdProfileUsingGet(id, {
        startDate: startDate !== undefined ? dayjs(startDate).utc().format('YYYY-MM-DDT00:00:00') : undefined,
        endDate: endDate !== undefined ? dayjs(endDate).utc().format('YYYY-MM-DDT23:59:59') : undefined,
    });
    return data;
};

export const getUserAvatarById = async (id: number): Promise<string> => {
    const userPhotoApi = new UserPhotoController();

    const { data } = await userPhotoApi.apiV1UsersUseridPhotoUsingGet(id);

    return (data as unknown) as string;
};

export const getCurrentUser = async (): Promise<unknown> => {
    const userApi = new UserController();

    const { data } = await userApi.apiV1UsersMeUsingGet();

    const { authorities, ...other } = data;
    return { ...other, role: authorities };
};

export const updateUser = async (userId: number, user: UserProfileRequestDto): Promise<void> => {
    const userApi = new UserController();

    await userApi.apiV1UsersIdProfileUsingPut(userId, user);
};

export const updateUserAvatar = async (userId: number, avatarMediaId: number) => {
    const userApi = new UserController();

    const { data } = await userApi.apiV1UsersIdAvatarUsingPut(userId, { avatarId: avatarMediaId });

    return data;
};

export const removeUserAvatar = async (userId: number): Promise<void> => {
    const userApi = new UserController();

    await userApi.apiV1UsersIdAvatarUsingDelete(userId);
};

export const inviteUser = async (request: InviteUserRequestDto): Promise<void> => {
    const azureApi = new AzureAuthController();
    await azureApi.apiV1Oauth2MicrosoftInviteUsingPost(request);
};
